export const menuData = [
    /* {title: "О Нас", link: "/about"}, */
    {title: "Wester", link: "/Wester"},
    {title: "MasDaf", link: "/MasDaf"},
    {title: "Reflex", link: "/Reflex"},
    {title: "Zilmet", link: "/Zilmet"},
    {title: "Flamco", link: "/Flamco"},
    {title: "Cruwa", link: "/Cruwa"},
    {title: "Контакты", link: "/Contact"},
    /* {title: "Прайс", link: "/page-2"}, */
]